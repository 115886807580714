import './App.css';

export default function App() {
  return (
    <div className='containerApp'>
      <h1>Aula de Web Designer</h1>
      <h2>Aulas no Senac Bauru</h2>
    </div>
  )
}

